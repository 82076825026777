const moment = require("moment");

const _clearRunAndChargeEvents = async (db) => {
  // Import the DB
  const { meshDb } = await import("../../firebase");

  // Name of the collection to store calculated events
  const collection = "powerUsageEvents";

  // Fetch all the databases sensors
  const sensorQuery = await meshDb
    .collection(db)
    .get();

  if (!sensorQuery.empty) {
    for (let doc of sensorQuery.docs) {
      const batch = meshDb.batch();
      const docId = doc.id;
      console.log(`==== Clearing "${collection}" for document: ${docId} ====`);
      const sensorEventsQuery = await meshDb
        .collection(db)
        .doc(docId)
        .collection(collection)
        .limit(500) // max writes allowed per run...
        .get();

      // Get the events and remove them...
      console.log(`     Found ${sensorEventsQuery.size} items in collection, looping and clearing...`)
      if (!sensorEventsQuery.empty) {
        let docRef;
        for (let eventDoc of sensorEventsQuery.docs) {
          docRef = meshDb
            .collection(db)
            .doc(docId)
            .collection(collection)
            .doc(eventDoc.id);
          batch.delete(docRef);
        }
      }
      await batch.commit();
      console.log(`==== Done clearing "${collection}" for document: ${docId} ====`)
    }
  }
}

/*
* Run calculations on Run and Charge events for units *
*
* @param db string
* @param unitID string|null
*
* @returns null
*/
const _calculateRunAndChargeEvents = async (db, unitID = null) => {
  // Import the DB
  const { meshDb } = await import("../../firebase");

  // How many sensors to fetch per run?
  const sensorsPerRun = 5; // Max run is ~5-7s per sensor, avg. ~500ms

  // How many data points to work on at a time
  const dataPointToProcess = 5000;

  // Name of the collection to store calculated events
  const collection = "powerUsageEvents";

  // Get the start of the year as a starting point to process
  const start = moment.utc().startOf("year")
    .toISOString()
    .replace("T", " ")
    .slice(0, -5);

  // Get the date 30 days ago
  const last30 = moment.utc().subtract(30, 'days')
    .toISOString()
    .replace("T", " ")
    .slice(0, -5);

  // Get the unit ID
  const docs = [];
  if (unitID) {
    docs.push(unitID);
  } else {
    // Fetch all the databases sensors (active within the last 30days)
    const sensorQuery = await meshDb
      .collection(db)
      .where("MessageUTC", ">", last30)
      .get();

    // Grab a random sensors
    for (let i = 0; i < sensorsPerRun; ++i) {
      let doc = await _getRandomDoc(meshDb, db, sensorQuery, docs);
      docs.push(doc.id);
    }
  }

  // Keep track of results
  const calculationResults = [];
  for (const doc of docs) {
    const id = doc;
    // Get the last power usage event (if there is one)
    const powerEventQuery = await meshDb
      .collection(db)
      .doc(id)
      .collection(collection)
      .orderBy("updateTimestamp", "desc")
      .limit(1)
      .get();

    // Set the latest event based on value received
    let lastEvent = null;
    if (!powerEventQuery.empty) {
      // Only concerned with first element (should only be one anyway)
      lastEvent = powerEventQuery.docs[0];
    }

    // DEBUG
    console.log(
      `=== START: Processing events on DB "${db}", for sensor "${id} ===`
    );

    /*
     * Set dates
     *
     * Start from last events updated timestamp,
     * else from beginning of year
     */

    const from = lastEvent ?
      lastEvent.data().updateTimestamp : start;

    // DEBUG
    console.log("Last event ID:", lastEvent ? lastEvent.id : null);
    console.log("Starting from:", from);

    // Fetch the results
    const querySnapshot = await meshDb
      .collection(db)
      .doc(id)
      .collection("powerUsageReport")
      .orderBy("MessageUTC", "asc")
      .where("MessageUTC", ">", from)
      .limit(dataPointToProcess)
      .get();

    // Process the results
    const results = await _onSnapshot(querySnapshot, lastEvent);

    // DEBUG
    console.log(`Updating/inserting ${results.length} events`);

    // Store them back to the database
    if (results.length) {
      const batch = meshDb.batch();
      // Can only insert 500 records at a time
      results.slice(0, 500).forEach((doc) => {
        let docRef;
        const docId = doc.id || null;
        if (docId) {
          docRef = meshDb
            .collection(db)
            .doc(id)
            .collection(collection)
            .doc(docId); // Reference the doc to update
          batch.update(docRef, doc);
        } else {
          docRef = meshDb
            .collection(db)
            .doc(id)
            .collection(collection)
            .doc(); // Automatically generate unique id
          batch.set(docRef, doc);
        }
      });

      // Commit the changes
      await batch.commit();
    } else {
      console.log(
        `Sensor "${id}" has no "powerUsageReport" collection results`
      );
    }

    // DEBUG
    console.log(
      `=== COMPLETE: Processing events on DB "${db}", for sensor "${id} ===`
    );

    calculationResults.push({
      eventCount: results.length,
      id,
      from,
    });
  }

  const result = calculationResults[0];
  return unitID ? {
    eventCount: result.eventCount,
    id: result.id,
    from: result.from,
  } : calculationResults;
};

const _getRandomDoc = async (
  firebase,
  db,
  docsQuery,
  lastDoc = null,
  loop = 0
) => {
  // Get a random doc from the query
  let doc = docsQuery.docs[Math.floor(Math.random() * docsQuery.size)];
  // Check if we have previously checked documents
  if (lastDoc && Array.isArray(lastDoc)) {
    // Make sure we haven't already exhausted our options
    if (docsQuery.size > lastDoc.length) {
      // Get a random doc not yet reviewed
      while (lastDoc.includes(doc.id)) {
        doc = docsQuery.docs[Math.floor(Math.random() * docsQuery.size)];
      }
    }
  }

  // Format the dates
  const from = moment.utc().startOf("year")
    .toISOString().replace("T", " ").slice(0, -5);

  // Make sure `powerUsageReport` collection exists
  const powerUsageQuery = await firebase
    .collection(db)
    .doc(doc.id)
    .collection("powerUsageReport")
    .where("MessageUTC", ">", from)
    .limit(1)
    .get();

  // See if sensor has been checked previously
  // const { EventReportUTC = null } = doc.data();
  // if (EventReportUTC) {
  //   const lastCheck = moment.utc(EventReportUTC)
  //     .toISOString().replace("T", " ").slice(0, -5);
  //   const twentyFourAgo = moment.utc().subtract(1, 'days')
  //     .toISOString().replace("T", " ").slice(0, -5);
  // }

  // Nothing found (only recurse a max of eight times)
  if (powerUsageQuery.size === 0 && loop < 8) {
    console.log(`No "powerUsageReport" for: ${doc.id}, fetching new sensor...`);
    let checked = [];
    if (lastDoc && Array.isArray(lastDoc)) {
      checked = lastDoc;
    }
    checked.push(doc.id);
    return _getRandomDoc(
      firebase,
      db,
      docsQuery,
      checked,
      loop + 1
    );
  }

  // Return the doc
  return doc;
}

const _onSnapshot = async (querySnapshot, lastEvent) => {
  // Setup tmp array to house our data
  const tmpData = [];

  // How many minutes allowed b/w events before marking as new?
  const timeGap = 2;

  // Keep track of last item
  let lastDataPoint = lastEvent ? lastEvent.data() : null;
  if (lastDataPoint) {
    // If last event passed, start array with value
    tmpData.push({
      // Add document id to flag this item as an update
      id: lastEvent.id,
      ...lastDataPoint,
    });
  }

  // Loop through the data
  querySnapshot.docs.forEach((doc) => {
    const timestamp = doc.data().MessageUTC;
    const utcTime = new Date(timestamp + "Z");
    const localTime = utcTime.toLocaleString();
    let diffInMinutes = 0;

    const {
      ampHours,
      wattHours,
      averagePower,
      averageCurrent,
      averageVoltage,
    } = doc.data().Payload;

    let ampAndWattData;
    if (ampHours === 0 && wattHours === 0) {
      ampAndWattData = {
        ampHours: parseFloat(averageCurrent) * (30/3600),
        wattHours: (parseFloat(averagePower) * (30/3600)) / 1000,
      };
    } else {
      ampAndWattData = {
        ampHours,
        wattHours: wattHours / 1000,
      };
    }
    const data = {
      timestamp,
      date: localTime,
      avgPower: parseFloat(averagePower),
      avgCurrent: parseFloat(averageCurrent),
      avgVoltage: parseFloat(averageVoltage),
      ...ampAndWattData,
    };

    // Check if time-lapse greater than 1-2min, if so, close out previous item
    if (lastDataPoint) {
      const previous = moment(lastDataPoint.updateTimestamp);
      const next = moment(timestamp);
      diffInMinutes = next.diff(previous, "minutes");
      console.log('Last item diff in mins: ', diffInMinutes)
    }

    // Check for three scenarios:
    // 1. averagePower < 0 === charge event
    // 2. averagePower > 0 === run event
    // 3. averagePower === 0 === off event
    let event = null;
    if (averagePower < 0) {
      /*
       * Current item is a charge event
       */
      if (
        diffInMinutes <= timeGap &&
        lastDataPoint && lastDataPoint.type === "Charge"
      ) {
        /*
         * If current and last items are charge events, this is a
         * continuing charge event.
         */
        const lastEvent = tmpData.shift();
        const payload = {
          totalPower: lastEvent.totalPower + (Math.abs(data.avgPower) / 1000),
          totalCurrent: lastEvent.totalCurrent + Math.abs(data.avgCurrent),
          totalVoltage: lastEvent.totalVoltage + data.avgVoltage,
          totalAmpHours: lastEvent.totalAmpHours + data.ampHours,
          totalKWH: lastEvent.totalKWH + data.wattHours,
          updated: localTime,
          updateTimestamp: timestamp,
        };
        // Update the last charge event
        event = {
          ...lastEvent,
          runtime: _getTimeSpent(lastEvent.startTimestamp, timestamp),
          ...payload,
          payload,
        };
      } else {
        // Create new charge event
        const payload = {
          // Convert Watt to KWatt
          totalPower: Math.abs(data.avgPower) / 1000,
          totalCurrent: Math.abs(data.avgCurrent),
          totalVoltage: data.avgVoltage,
          totalAmpHours: data.ampHours,
          totalKWH: data.wattHours,
          updated: localTime,
          updateTimestamp: timestamp,
        };
        event = {
          start: localTime,
          startTimestamp: timestamp,
          runtime: "N/A",
          type: "Charge",
          ...payload,
          payload,
        };
      }
    } else if (averagePower > 0) {
      /*
       * Current item is a run event
       */
      if (
        diffInMinutes <= timeGap &&
        lastDataPoint && lastDataPoint.type === "Run"
      ) {
        /*
         * If current and last items are run events, this is a
         * continuing run event.
         */
        const lastEvent = tmpData.shift();
        const payload = {
          totalPower: lastEvent.totalPower + (Math.abs(data.avgPower) / 1000),
          totalCurrent: lastEvent.totalCurrent + Math.abs(data.avgCurrent),
          totalVoltage: lastEvent.totalVoltage + data.avgVoltage,
          totalAmpHours: lastEvent.totalAmpHours + data.ampHours,
          totalKWH: lastEvent.totalKWH + data.wattHours,
          updated: localTime,
          updateTimestamp: timestamp,
        };
        // Update the last run event
        event = {
          ...lastEvent,
          runtime: _getTimeSpent(lastEvent.startTimestamp, timestamp),
          ...payload,
          payload,
        };
      } else {
        // Create new run event
        const payload = {
          // Convert Watt to KWatt
          totalPower: Math.abs(data.avgPower) / 1000,
          totalCurrent: Math.abs(data.avgCurrent),
          totalVoltage: data.avgVoltage,
          totalAmpHours: data.ampHours,
          totalKWH: data.wattHours,
          updated: localTime,
          updateTimestamp: timestamp,
        };
        event = {
          start: localTime,
          startTimestamp: timestamp,
          runtime: "N/A",
          type: "Run",
          ...payload,
          payload,
        };
      }
    } else if (averagePower === 0) {
      /*
       * Current item is an off event
       */
      if (
        diffInMinutes <= timeGap &&
        lastDataPoint && lastDataPoint.type === "Off"
      ) {
        /*
         * If current and last items are off events, this is a
         * continuing off event.
         */
        const lastEvent = tmpData.shift();
        const payload = {
          totalPower: lastEvent.totalPower + (Math.abs(data.avgPower) / 1000),
          totalCurrent: lastEvent.totalCurrent + Math.abs(data.avgCurrent),
          totalVoltage: lastEvent.totalVoltage + data.avgVoltage,
          totalAmpHours: lastEvent.totalAmpHours + data.ampHours,
          totalKWH: lastEvent.totalKWH + data.wattHours,
          updated: localTime,
          updateTimestamp: timestamp,
        };
        // Update the last run event
        event = {
          ...lastEvent,
          runtime: _getTimeSpent(lastEvent.startTimestamp, timestamp),
          ...payload,
          payload,
        };
      } else {
        // Create new run event
        const payload = {
          // Convert Watt to KWatt
          totalPower: Math.abs(data.avgPower) / 1000,
          totalCurrent: Math.abs(data.avgCurrent),
          totalVoltage: data.avgVoltage,
          totalAmpHours: data.ampHours,
          totalKWH: data.wattHours,
          updated: localTime,
          updateTimestamp: timestamp,
        };
        event = {
          start: localTime,
          startTimestamp: timestamp,
          runtime: "N/A",
          type: "Off",
          ...payload,
          payload,
        };
      }
    } else {
      // ...not run or charge or off event...
    }

    // Add the event data to the resulting array
    if (event) {
      tmpData.unshift(event);
    }

    // Keep track the most recent data point
    lastDataPoint = event;
  });

  // Return the results
  return tmpData;
};

const _getTimeSpent = (startTimestamp, endTimestamp = null) => {
  // Parse the strings
  const started = moment.utc(startTimestamp);
  const stopped = endTimestamp ? moment.utc(endTimestamp) : moment().utc();

  // Get the hours, minutes, seconds difference
  const diff = {
    hours: stopped.diff(started, "hours"),
    minutes: stopped.diff(started, "minutes"),
    seconds: stopped.diff(started, "seconds"),
  };

  // Get the relative differences -
  // since hr, min, sec will each be total time diff,
  // need to massage a bit to get relative values
  let time = {
    hours: diff.hours,
    minutes: diff.hours >= 1 ?
      diff.minutes - (60 * diff.hours) :
      diff.minutes,
    seconds: diff.minutes >= 1 ?
      diff.seconds - (60 * diff.minutes) :
      diff.seconds,
  };
  // Format it with leading 0
  time = {
    hours: time.hours,
    minutes: time.minutes.toString().padStart(2, "0"),
    seconds: time.seconds.toString().padStart(2, "0"),
  };

  // Return the formatted time string
  return `${time.hours}:${time.minutes}:${time.seconds}s`;
};

export {
  _calculateRunAndChargeEvents,
  _clearRunAndChargeEvents
}
