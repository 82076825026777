<template>
  <card classs="card">
    <h4 slot="header" class="card-title">Registered Hardware</h4>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <base-input>
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table
        :data="queriedData"
        :default-sort = "{prop: 'friendlyName', order: 'descending'}"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
          sortable
        >
        </el-table-column>
        <el-table-column :min-width="100" align="left" label="Actions">
          <div slot-scope="props">
            <el-tooltip
              content="Open Unit Dashboard"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click.native="gotoDashboard(props.$index, props.row)"
                class="like btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="tim-icons icon-minimal-up"></i>
              </base-button>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import router from "../../routes/router"
import { entitiesCollection } from "../../firebase"

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["mobileID", "model", "friendlyName"],
      tableColumns: [
        {
          prop: "mobileID",
          label: "Mobile ID",
          minWidth: 165
        },
        {
          prop: "model",
          label: "Model",
          minWidth: 175
        },
        {
          prop: "friendlyName",
          label: "Friendly Name",
          minWidth: 175
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    async getHardware() {
      const entityID = this.$store.getters.userProfile.entityID;
      const userRole = this.$store.getters.userProfile.role;
      if (userRole == "endUser") {
        const unitList = this.$store.getters.userProfile.units;
        for (const unit of unitList) {
          let doc = await entitiesCollection
            .doc(entityID)
            .collection("hardware")
            .doc(unit)
            .get();
          
          this.tableData.push({
            mobileID: doc.id,
            model: doc.data().model,
            friendlyName: doc.data().friendlyName
          });
        }
      } else {
        const hardwareListRef = entitiesCollection
          .doc(entityID)
          .collection("hardware");
        let hardwareList = await hardwareListRef.get();
        if (hardwareList.empty) {
          return;
        }
        hardwareList.forEach(doc => {
          this.tableData.push({
            mobileID: doc.id,
            model: doc.data().model,
            friendlyName: doc.data().friendlyName
          });
        });
      }
    },
    gotoDashboard(index, row) {
      let routerPath = "/dashboard/".concat(row.mobileID);
      router.push(routerPath);
    },
    initFuse() {
      this.fuseSearch = new Fuse(this.tableData, {
        includeMatches: true,
        findAllMatches: true,
        keys: ["mobileID", "model", "friendlyName"]
      });
    }
  },
  created() {
    this.getHardware();
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["mobileID", "model", "friendlyName"],
      threshold: 0.3
    });
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>
