<template>
  <div class="row">
    <div v-if="showRunChargeButton" class="col-md-12">
      <button @click="testRunAndChargeCalculations" class="btn">
        Process run/charge for Solectrac
      </button>
      <button @click="testRunAndChargeCalculationsWyeast" class="btn">
        Process run/charge for Wyeast
      </button>
    </div>
    <div v-if="showClearCalculationsButton" class="col-md-12">
      <button @click="clearRunAndChargeCalculations" class="btn">
        Clear run/charge for Solectrac
      </button>
      <button @click="clearRunAndChargeCalculationsWyeast" class="btn">
        Clear run/charge for Wyeast
      </button>
    </div>
    <div v-if="showUpdateButton" class="col-md-12">
      <button @click="updateSelectracTemplateId" class="btn">
        Update Solectrac e25 sensors
      </button>
      <button @click="updateWyeastTemplateId" class="btn">
        Update Wyeast e25 sensors
      </button>
    </div>
    <div class="col-md-12">
      <HardwareList></HardwareList>
    </div>
    <div class="col-md-12">
      <GoogleMaps></GoogleMaps>
    </div>
  </div>
</template>
<script>
import HardwareList from '@/pages/Hardware/HardwareList';
import GoogleMaps from '@/pages/Maps/GoogleMaps';
import { meshDb } from "@/firebase";
import {_calculateRunAndChargeEvents, _clearRunAndChargeEvents} from './_testFunctions'

export default {
  components: {
    HardwareList,
    GoogleMaps
  },
  data() {
    return {
      showUpdateButton: false,
      showRunChargeButton: false,
      showClearCalculationsButton: false,
      db: 'db-solectrac',
      db2: 'db-wyeast'
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    testRunAndChargeCalculations() {
      _calculateRunAndChargeEvents(this.db)
        .then(results => console.log('_calculateRunAndChargeEvents', results));
    },
    clearRunAndChargeCalculations() {
      _clearRunAndChargeEvents(this.db);
    },
    testRunAndChargeCalculationsWyeast() {
      _calculateRunAndChargeEvents(this.db2)
        .then(results => console.log('testRunAndChargeCalculationsWyeast', results));
    },
    clearRunAndChargeCalculationsWyeast() {
      _clearRunAndChargeEvents(this.db2);
    },
    updateSelectracTemplateId() {
      const sensors = []
      meshDb
        .collection(this.db)
        .limit()
        .get()
        .then(async(snapshot) => {
          for (let doc of snapshot.docs) {
            const id = doc.id
            const result = await meshDb
              .collection(this.db)
              .doc(id)
              .collection('digitalInputlifeSignal')
              .limit(1)
              .get();

            if (result.size) {
              sensors.push({
                id,
                data: doc.data()
              });
            }
          }
          await this.updateSensorTemplates(sensors);
        });
    },
    updateWyeastTemplateId() {
      const sensors = []
      meshDb
        .collection(this.db2)
        .limit()
        .get()
        .then(async(snapshot) => {
          for (let doc of snapshot.docs) {
            const id = doc.id
            const result = await meshDb
              .collection(this.db2)
              .doc(id)
              .collection('digitalInputlifeSignal')
              .limit(1)
              .get();

            if (result.size) {
              sensors.push({
                id,
                data: doc.data()
              });
            }
          }
          await this.updateSensorTemplates(sensors);
        });
    },
    async updateSensorTemplates(sensors) {
      for (const s of sensors) {
        const docRef = meshDb.collection(this.db).doc(s.id)
        await docRef.update({ TemplateID: 'solectrac_e25_dynamic' })
      }
    }
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style></style>
