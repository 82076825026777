<template>
<div>
  <card class="card">
    <h4 slot="header" class="card-title">Hardware Map</h4>
    <div id="satelliteMap" class="map map-big"></div>
    <div slot="footer"></div>
  </card>
</div>
</template>
<script>
import { meshDb, firestore } from "@/firebase";
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
import OverlappingMarkerSpiderfier from "overlapping-marker-spiderfier/lib/oms";
import moment from "moment";
import { bus } from "@/util/bus";

function zoomMarker(mobileID) {
  bus.$emit('zoomToMarker', {mobileID})
}
window.zoomMarker = zoomMarker

export default {
  name: "Map",
  data() {
    return {
      center: null,
      mapZoom: 5,
      markerZoom: 18,
      nrtime: true,
      firstRun: true,
      observer: null,
      infoWindow: null,
      dateFormat: 'M/D/YYYY, h:mm:ss A',
      map: null,
      oms: null,
    };
  },
  computed: {

  },
  methods: {
    getCenter() {
      let latsTotal = 0;
      let longsTotal = 0;
      let count = 0;
      this.map.markers.forEach(marker => {
        const lat = marker.position.lat();
        if (!isNaN(lat)) {
          latsTotal += lat;
        }
        const lng = marker.position.lng();
        if (!isNaN(lng)) {
          longsTotal += lng;
        }
        count += 1;
      });
      if (count > 0) {
        let newLat = latsTotal / count;
        let newLon = longsTotal / count;
        this.center = new google.maps.LatLng(newLat, newLon);
      } else {
        this.center = new google.maps.LatLng(40.403, -105.08);
      }
    },
    async getUnits() {
      const database = this.$store.getters.userDatabase
      const userRole = this.$store.getters.userProfile.role;
      this.firstRun = true

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {
      }
      
      if (userRole == "endUser") {
        const unitList = this.$store.getters.userProfile.units;
        this.observer = await meshDb
          .collection(database)
          .where("MobileID", "in", unitList)
          .onSnapshot(this._onSnapshot);
      } else {
        this.observer = await meshDb
          .collection(database)
          .onSnapshot(this._onSnapshot);
      }
    },
    async _onSnapshot (querySnapshot, force = false) {
      // If not real time (or passing `force` flag) return
      if (!(this.nrtime || force)) {
        return null
      }

      // Loop through the data
      let shouldCenter = false
      let shouldPan = false

      async function isActivated(change, docID, data, mobileID) {
        try {
          let doc = await meshDb.collection('hardware').doc(docID).get();
  
          if (!doc.exists) {
            const query = await meshDb.collection('hardware').where('dataId', '==', docID).get();
            doc = query.docs[0];
            docID = doc.id;
          }
          
          if (doc.exists) {
            const activated = doc.data().activated;
            //DEBUG
            //console.log("docID: ", docID, activated)

            if (activated) {
              const { latitude, longitude } = data.Payload
              const MessageUTC = data['MessageUTC']

              // DEBUG
              // console.log('🗺 marker snapshot received', mobileID, change.type, payload)

              switch (change.type) {
                case 'added':
                case 'modified':
                  // If markers added/modified, flag should center as true
                  shouldCenter = true

                  // Get marker data
                  const updated = moment.utc(MessageUTC);
                  const tenMinAgo = moment.utc().subtract(10, 'minutes');
                  const diff = tenMinAgo.diff(updated, 'hours');
                  const status = diff > 0 ? 'red' : 'green'; // if updated more than 24hr ago, flag as red, else green
                  const description = `<h5>
                                          Current location for <strong>${mobileID}</strong>
                                      </h5>
                                      <button class="btn btn-sm d-block zoom" onclick="zoomMarker('${mobileID}')">Zoom to marker</button>
                                      <a class="btn btn-sm d-block" href="/dashboard/${mobileID}">View Unit Dashboard</a>
                                      <hr/>
                                      <small style="color: #383838">Updated: ${updated.local().format(this.dateFormat)}</small>`;

                  // Get the position
                  const latlng = new google.maps.LatLng(latitude, longitude)

                  // Determine if modified, or new
                  if (change.type === 'added') {
                    // If new markers added, pan map
                    shouldPan = true

                    // Create a new marker
                    try {
                      this.createMarker(latlng, description, mobileID, status)
                    } catch (e) { return; }
                  } else {
                    // If modified, find the marker and update its data
                    const marker = this.map.markers.find(m => m.id === mobileID)
                    if (marker) {
                      // Update marker position and description
                      marker.setPosition(latlng)
                      marker.icon = this.getMarkerIcon(status)
                      marker.description = description
                    }
                  }
                  break
                case 'removed':
                  // Find the unit to remove/disable
                  const markerIndex = this.map.markers.findIndex(m => m.id === mobileID);
                  if (markerIndex !== -1) {
                    try {
                      // Clear the marker from the map
                      this.map.markers[markerIndex].setMap(null)
                      // Remove it from the Spiderify tracking
                      this.oms.removeMarker(this.map.markers[markerIndex])
                      // Finally, remove it from the map markers array
                      this.map.markers.splice(markerIndex, 1)
                    } catch (e) { }
                  }
                  break
              }
            }
          } else {
            console.log(docID, ' not found in "hardware"')
          }
        } catch (error) {
          console.log('Error retrieving document ', docID, error);
          return;
        }
      }

      // Loop through the data
      const asyncTasks = querySnapshot.docChanges().map(async change => {
        const docID = change.doc.id
        const data = change.doc.data()
        const mobileID = data.MobileID

        // Make sure there is a payload before proceeding
        if (!data.Payload) { return; }
        await isActivated.call(this, change, docID, data, mobileID);
      });

      await Promise.all(asyncTasks)

      //DEBUG
      //console.log("center: ", shouldCenter, ", pan: ", shouldPan);
      
      // Check if we need to center or pan the map
      if (shouldCenter) {
        this.getCenter()
      }
      if (shouldPan) {
        this.map.panTo(this.center)
      }

      // Add clustering?
      // const markerCluster = new MarkerClusterer({
      //   markers: this.map.markers, map: this.map
      // });

      // Flag first run as false for subsequent function call
      this.firstrun = false;
    },
    createMarker(latlng, description, mobileID, type) {
      const marker = new google.maps.Marker({
        map: this.map,
        position: latlng,
        title: mobileID,
        optimized: true,
        icon: this.getMarkerIcon(type),
        description
      });

      // Set a unique identifier
      marker.set('id', mobileID)

      // Add to map markers and Spiderify
      this.map.markers.push(marker)
      this.oms.addListener('click', (marker, event) => {
        this.infoWindow.setContent(marker.description);
        this.infoWindow.open(this.map, marker);
      })
      this.oms.addMarker(marker);
    },
    getMarkerIcon(type) {
      const c1 = type === 'green' ? "#5cea35" : "#EA4335";
      const c2 = type === 'green' ? "#12a60d" : "#A60D0D";
      const size = 36;
      const svg = `<svg height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 86.96 122.88" xml:space="preserve"><g>
                     <path fill-rule="evenodd" clip-rule="evenodd" fill="${c1}" d="M27.81,96.57c4.42,7.04,9.2,15.57,13.92,23.86c1.75,3.06,3.33,3.52,5.43-0.11c4.56-7.91,9.04-16.1,13.76-23.74 c14.33-23.19,37.78-45.8,19.13-77.01C63.49-8.14,18.45-5.18,4.98,20.62C-10.46,50.19,13.65,74.03,27.81,96.57L27.81,96.57z"/>
                     <path fill-rule="evenodd" clip-rule="evenodd" fill="${c2}" d="M43.46,25.59c9.31,0,16.86,7.55,16.86,16.86s-7.55,16.86-16.86,16.86c-9.31,0-16.86-7.55-16.86-16.86 S34.15,25.59,43.46,25.59L43.46,25.59z"/>
                   </g></svg>`;

      return {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
        anchor: new google.maps.Point(size / 2, size)
      }
    },
    createCenterControl() {
      const controlButton = document.createElement("button");

      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";
      controlButton.textContent = "Center Map";
      controlButton.title = "Click to recenter the map";
      controlButton.type = "button";

      // Set up the click event listeners: simply set the map to Chicago.
      controlButton.addEventListener("click", this.zoomAndCenterMap);
      return controlButton;
    },
    zoomAndCenterMarker(marker) {
      if (!marker) {
        return;
      }
      this.infoWindow.close()
      this.map.setZoom(this.markerZoom)
      this.map.panTo(marker.position)
    },
    zoomAndCenterMap() {
      // Get the map center, close the info window, set zoom, and pan to center
      this.getCenter()
      this.infoWindow.close()
      this.map.setZoom(this.mapZoom)
      this.map.panTo(this.center)
    },
    closeInfoWindow(args = null) {
      this.infoWindow && this.infoWindow.close()
    },
    setupMap() {
      if (!this.map) {
        // Instantiate the map
        this.map = new google.maps.Map(document.getElementById("satelliteMap"), {
          zoom: this.mapZoom,
          scrollwheel: false,
          center: this.center,
          mapTypeId: google.maps.MapTypeId.SATELLITE
        });

        // Set up the markers array
        this.map.markers = [];

        // Add "Center Map" button
        const centerControlDiv = document.createElement("div");
        const centerControl = this.createCenterControl();
        centerControlDiv.appendChild(centerControl);
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
      }

      // Set the global info window, if not already set
      if (!this.infoWindow) {
        this.infoWindow = new google.maps.InfoWindow();
        google.maps.event.addListener(this.map, 'click', this.closeInfoWindow);
      }

      // If Spiderify library not loaded, load it
      if (!this.oms) {
        // Info: https://github.com/jawj/OverlappingMarkerSpiderfier
        const config = {
          keepSpiderfied: true, // default: false
          spiralFootSeparation: 26, // default: 26
          spiralLengthStart: 15, // default: 11
          spiralLengthFactor: 4, // default: 4
          nearbyDistance: 20, // default: 20
          ignoreMapClick: false, // default: false
        }
        this.oms = new OverlappingMarkerSpiderfier(this.map, config)
        // this.oms.addListener('spiderfy', this.closeInfoWindow); // acts a little funky, seems to perform better without
      }

      // Fetch the latest data point
      this.getUnits()
    },
  },
  mounted() {
    // Set up the map
    this.setupMap()

    // Listen for marker zoom
    bus.$on('zoomToMarker', ({ mobileID }) => {
      const marker = this.map.markers.find(m => m.id === mobileID)
      if (marker) {
        this.zoomAndCenterMarker(marker)
      }
    })
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  }
};
</script>
<style lang="scss">
.card-map {
  min-height: 350px;
  .map {
    height: 300px;
    width: 100%;
  }
}
</style>
